<template>
    <div class="benefit">
        <CompTable title="健康指导服务记录" :columns="columns" :table-api="'/gateway/api/synursing/pc/record/listRecordByPage?orgCode=' + orgCode">
            <template #operate>
                <Button @click="route.jump('/benefitcat')">健康指导设置</Button>
            </template>
            <template v-slot:search="data">
                <Input v-model.trim="data.search.keyword" placeholder="长者姓名或手机号" style="width: 200px"></Input>
            </template>
        </CompTable>

        <CompModal ref="comp_modal" class="benefit-detail" :class="{ isexport: isExport }" title="服务记录详情" @on-load="onLoadModal">
            <div class="detail-base" v-if="detail" ref="detail_base">
                <div class="detail-box">
                    <div class="info-box">
                        <div class="item">姓名：{{ detail.userName }}</div>
                        <div class="item">性别：{{ ["未知", "男", "女"][detail.sex] || "-" }}</div>
                        <div class="item">年龄：{{ detail.age || "-" }}</div>
                    </div>

                    <div class="item-box">
                        <div class="title">
                            护理问题
                        </div>
                        <div class="content">{{ detail.content }}</div>
                    </div>

                    <div class="item-box" v-for="(item, idx) in detail.recordCatVos" :key="idx">
                        <div class="title">
                            {{ item.catName }} :
                            <checkbox style="margin:0 5px 0 20px;display:flex;" :disabled="true" :checked="true"></checkbox>
                            <span>{{ item.catItemName }}</span>
                        </div>
                        <div class="content">{{ item.catItemText }}</div>
                    </div>

                    <div class="item-box row" v-if="detail.photo">
                        <div class="title">
                            服务照片
                        </div>
                        <div class="content">
                            <img v-for="item in typeof detail.photo === 'string' ? [detail.photo] : detail.photo" :key="item" class="img" :src="item" @tap="onPredivImage(detail.photo === 'string' ? [detail.photo] : detail.photo, item)" />
                        </div>
                    </div>

                    <div class="item-box row">
                        <div class="title">
                            上门时间
                        </div>
                        <div class="content">{{ detail.operatorTime || detail.homeServiceTime ? $core.formatDate(new Date(detail.operatorTime || detail.homeServiceTime), "yyyy年MM月dd日") : "-" }}</div>
                    </div>

                    <div class="item-box row">
                        <div class="title">
                            服务人员
                        </div>
                        <div class="content">{{ detail.nursingUser || detail.fwry || "-" }}</div>
                    </div>

                    <div class="btn" v-if="type === 'guidance' && detail.appraiseStatus === '0'" @tap="$refs.appraise_form.display(id)">评价</div>
                </div>

                <div class="appraise-box" v-if="detail.appraiseVoList && detail.appraiseVoList.length > 0">
                    <div class="title">评价记录</div>
                    <CompForm :forms="appraise_forms" :value="detail.appraiseVoList[0]" :isDisplaySubmit="false" disabled></CompForm>
                </div>
            </div>
        </CompModal>

        <CompTabular ref="comp_tabular" title="健康指导记录单"></CompTabular>
    </div>
</template>

<script>
import Route from "../communitymobilization/entity/Route"
import CompForm from "../customform/components/CompForm.vue"
import CompTable from "../jointly/components/comp-table.vue"
import Request from "../jointly/utils/request"
import CompModal from "../residentdatabase/components/CompModal.vue"
import CompTabular from "../customform/components/CompTabular.vue"
import FileUtil from "../customform/utils/file"
import Loading from "../jointly/utils/loading"
import ElemButton from "../customform/components/ElemButton.vue"

export default {
    components: { CompTable, CompModal, CompForm, CompTabular },

    data() {
        const orgCode = this.$core.getUrlParam("orgCode") || parent.vue.loginInfo.userinfo.orgCode

        return {
            orgCode: orgCode,
            // 详情
            detail: null,
            // 路由控制器
            route: Route,

            columns: [
                {
                    title: "姓名",
                    key: "userName",
                },
                {
                    title: "性别",
                    key: "sex",
                    render: (h, params) => {
                        return ["未知", "男", "女"][params.row.sex]
                    },
                },
                {
                    title: "年龄",
                    key: "age",
                },
                {
                    title: "联系电话",
                    key: "mobile",
                },
                {
                    title: "服务人员",
                    key: "nursingUser",
                },
                {
                    title: "服务人员账号",
                    key: "createAccount",
                },
                {
                    title: "服务日期",
                    minWidth: 150,
                    render: (h, params) => {
                        return params.row.operatorTime ? this.$core.formatDate(new Date(params.row.operatorTime), "yyyy-MM-dd") : null
                    },
                },
                {
                    title: "提交时间",
                    minWidth: 200,
                    render: (h, params) => {
                        return this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss")
                    },
                },
                {
                    title: "评价状态",
                    render: (h, params) => {
                        let w = params.row.appraiseStatus === "0"
                        return h(
                            "p",
                            {
                                style: {
                                    color: w ? "red" : "",
                                },
                            },
                            w ? "待评价" : "已评价"
                        )
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Request.get("/gateway/api/synursing/app/record/selectRecordById?recordId=" + params.row.recordId).then(res => {
                                                this.detail = res

                                                this.$nextTick(() => {
                                                    this.$refs.comp_modal.display()
                                                })
                                            })
                                        },
                                    },
                                },
                                "记录"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Request.get("/gateway/api/synursing/app/record/selectRecordById?recordId=" + params.row.recordId).then(res => {
                                                const columns = [
                                                    {
                                                        child: [
                                                            {
                                                                child: [
                                                                    {
                                                                        value: (h, d) => `姓名：${d.userName || ""}`,
                                                                    },
                                                                    {
                                                                        value: (h, d) => `性别：${d.sex ? ["男", "女"][d.sex - 1] || "未知" : ""}`,
                                                                    },
                                                                    {
                                                                        value: (h, d) => `年龄：${d.age || ""}`,
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                value: (h, d) => `建档时间：${this.$core.formatDate(new Date(d.gmtCreate), "yyyy-MM-dd")}`,
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        title: "护理问题",
                                                        value: (h, d) => d.content || "",
                                                    },
                                                ]

                                                const group = {}

                                                res.recordCatVos.forEach(v => {
                                                    if (group[v.catName]) {
                                                        group[v.catName].push(v)
                                                    } else {
                                                        group[v.catName] = [v]
                                                    }
                                                })

                                                Object.keys(group).forEach(k => {
                                                    columns.push({
                                                        title: k,
                                                        value: h => {
                                                            const box = []

                                                            group[k].forEach((item, idx) => {
                                                                box.push(
                                                                    h(
                                                                        "p",
                                                                        {
                                                                            style: {
                                                                                "font-size": "16px",
                                                                                color: "#333",
                                                                                "font-weight": "500",
                                                                                margin: (idx === 0 ? "0" : "10px") + " 0 5px 0",
                                                                            },
                                                                        },
                                                                        item.catItemName + ":"
                                                                    ),
                                                                    h(
                                                                        "p",
                                                                        {
                                                                            style: {
                                                                                "font-size": "13px",
                                                                                color: "#666",
                                                                                "letter-spacing": "1px",
                                                                            },
                                                                        },
                                                                        item.catItemText
                                                                    )
                                                                )
                                                            })

                                                            return h("div", box)
                                                        },
                                                    })
                                                })

                                                columns.push({
                                                    sort: "row",
                                                    child: [
                                                        {
                                                            value: (h, d) => `康护师：${d.nursingUser || ""}`,
                                                        },
                                                        {
                                                            value: (h, d) => `${this.$core.formatDate(new Date(d.operatorTime), "yyyy年MM月dd日")}`,
                                                        },
                                                    ],
                                                })

                                                this.$refs.comp_tabular.display(res, columns)
                                            })
                                        },
                                    },
                                },
                                "导出"
                            ),
                            h(
                                ElemButton,
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                        onClick: () => {
                                            return new Promise(resolve => {
                                                Request.get("/gateway/api/synursing/app/record/selectRecordById?recordId=" + params.row.recordId).then(res => {
                                                    Loading.show()

                                                    this.__onLoadModal = () => {
                                                        setTimeout(async () => {
                                                            await FileUtil.exportToPDF(this.$refs.detail_base)

                                                            this.$refs.comp_modal.close()
                                                            this.isExport = false

                                                            Loading.hide()

                                                            setTimeout(resolve, 1000)
                                                        }, 100)
                                                    }

                                                    this.isExport = true
                                                    this.detail = res

                                                    this.$nextTick(() => {
                                                        this.$refs.comp_modal.display()
                                                    })
                                                })
                                            })
                                        },
                                    },
                                    style: "margin:0 3px",
                                },
                                "导出记录"
                            ),
                        ])
                    },
                },
            ],

            appraise_forms: [
                {
                    type: "rate",
                    name: "orgLevel",
                    title: "您对服务机构提供的服务内容是否满意？",
                    required: true,
                },
                {
                    type: "rate",
                    name: "staffLevel",
                    title: "您对工作人员的服务态度是否满意？",
                    required: true,
                },
                {
                    type: "rate",
                    name: "qualityLevel",
                    title: "您对工作人员的服务质量是否满意？",
                    required: true,
                },
                {
                    type: "textarea",
                    name: "suggest",
                    title: "其他建议",
                },
            ],

            isExport: false,
        }
    },

    methods: {
        onLoadModal() {
            // 触发事件
            this.__onLoadModal?.()
            // 销毁事件
            this.__onLoadModal = null
        },
    },
}
</script>

<style lang="less">
.benefit-detail {
    .detail-base {
        display: flex;
        height: 100%;
    }

    .detail-box {
        width: 500px;
        height: 100%;
        overflow: auto;

        .info-box {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #e3e3e3;
        }

        .item-box {
            margin: 15px 20px;

            .title {
                font-size: 14px;
                color: #888;
                line-height: 25px;
                display: flex;
                align-items: center;
                flex-shrink: 0;

                .taro-checkbox_checked {
                    top: 0;
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                }

                &.bold {
                    font-weight: bold;
                    font-size: 16px;
                    color: #333;
                    margin-bottom: 10px;
                }
            }

            .content {
                color: #333;
                border-radius: 5px;
                font-size: 14px;

                .img {
                    margin: 0 10px 10px 0;
                    width: 125px;
                    height: 125px;
                    border-radius: 5px;
                    overflow: hidden;
                    float: left;
                }
            }

            &:not(.row) .content {
                margin-top: 5px;
                padding: 10px;
                background: #f5f5f5;
            }

            &.row {
                display: flex;
                align-items: flex-start;

                .title {
                    position: relative;
                    padding-right: 10px;

                    &::after {
                        content: ":";
                        position: absolute;
                        top: 0;
                        right: 2px;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                    }
                }

                .content {
                    line-height: 20px;
                    margin: 2px 0 2px 10px;
                }
            }

            .value-item {
                margin-bottom: 12px;
                display: flex;

                .name {
                    color: #888;
                    line-height: 30px;
                    font-size: 14px;
                }

                .unit {
                    line-height: 30px;
                }

                .value {
                    padding: 0 30px;
                    margin: 0 10px;
                    background: #eeeeee;
                    border-radius: 5px;
                    height: 30px;
                    line-height: 30px;
                }

                .checkbox-box {
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;

                    .taro-checkbox_checked {
                        margin-right: 5px;
                    }
                }

                .radio-box {
                    margin-left: 10px;
                }

                span {
                    margin-left: 10px;
                    line-height: 30px;
                }
            }
        }
    }

    .appraise-box {
        height: 100%;
        overflow: auto;
        border-left: 1px solid #e3e3e3;

        > .title {
            font-size: 18px;
            font-weight: bold;
            padding: 15px 10px 0 10px;
            text-align: center;
        }

        .comp-form-page {
            height: initial;
            padding: 0 20px;
        }
    }

    &.isexport {
        z-index: -1 !important;
        opacity: 0;

        .detail-base,
        .detail-box,
        .appraise-box {
            height: auto;
            min-height: 100%;
        }
    }
}
</style>
